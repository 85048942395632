import { DateTime } from "luxon";

export const toDateString = (date) => DateTime.fromJSDate(date, { zone: 'Europe/Berlin' }).toFormat('ccc, dd.MM.yyyy \'a\'b HH:mm', { locale: 'de' }) + ' Uhr';
export const toDateFlexString = (date) => DateTime.fromJSDate(date, { zone: 'Europe/Berlin' }).toFormat('ccc, dd.MM \'a\'b HH:mm', { locale: 'de' }) + ' Uhr';
export const toShortDateString = (date) => DateTime.fromJSDate(date, { zone: 'Europe/Berlin' }).toFormat('dd.MM.yy');
export const toTimeString = (date) => DateTime.fromJSDate(date, { zone: 'Europe/Berlin' }).toFormat('HH:mm') + ' Uhr';

const weekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
export const resolveRepeat = repeat => {
    if(!repeat) return '';
    const parts = [];
    for(const x of Object.keys(repeat)) {
        let part = 'jeden ';
        switch(x) {
            case 'first': part += 'ersten '; break;
            case 'second': part += 'zweiten '; break;
            case 'third': part += 'dritten '; break;
            case 'fourth': part += 'vierten '; break;
            case 'fifth': part += 'fünften '; break;
            default: break;
        }
        part += repeat[x].weekdays.map(weekday => weekdays[weekday]).join(', ')??'';
        parts.push(part);
    }
    return parts.join(', ');
};
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, FieldValue, GeoPoint, Timestamp } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

export { FieldValue, GeoPoint, Timestamp };

const firebaseConfig = {
  apiKey: "AIzaSyCXVibXLkjpycRSVilz2MFpdptPJUKGyKc",
  authDomain: "elgio-invited.firebaseapp.com",
  databaseURL: "https://elgio-invited.firebaseio.com",
  projectId: "elgio-invited",
  storageBucket: "elgio-invited.appspot.com",
  messagingSenderId: "915261911218",
  appId: "1:915261911218:web:70d44f35abaced6edf59ab",
  measurementId: "G-30TH7RKFEK"
};

const app = initializeApp(firebaseConfig);

//Auth
export const auth = getAuth(app);

//Firestore
export const firestore = getFirestore(app);

//Functions
export const functions = getFunctions(app);

//Storage
export const storage = getStorage(app);
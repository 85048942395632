import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faHamburger } from "@fortawesome/free-solid-svg-icons";
import { algolia, } from "services/algolia";

import { CityContext } from "App";
import { cityIcon, downIcon, eventsIcon, marketingIcon, rightIcon, searchIcon, statisticsIcon, ticketingIcon, websiteIcon } from "icons";


import "./Navbar.css";

import logo from "images/general/newIcon_white.webp";
import FirebaseStorageImage from "./FirebaseStorageImage";
import { resolveRepeat } from "helpers/datetime";


export default function Navbar() {
    const location = useLocation();

    const navbarRef = useRef();
    const contentRef = useRef();

    const {cities} = useContext(CityContext);

    const showEasterEgg = useMemo(() => Math.random() < 0.005, []);

    const toDateString = (date) => date.toLocaleDateString('de-DE', {year: '2-digit', month: 'short', day: '2-digit', weekday: 'short'});

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const onResize = () => setWidth(window.innerWidth);
        window.removeEventListener('resize', onResize);
        window.addEventListener('resize', onResize, { passive: true });
        return () => window.removeEventListener('resize', onResize);
    }, []);

    //open and close content logic
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if(!contentRef.current) return;
        if(!open && width < 900) {
            // contentRef.current.style.visibility = 'hidden';
            contentRef.current.style.transform = 'scaleY(0)';
        } else {
            // contentRef.current.style.visibility = 'visible';
            contentRef.current.style.transform = 'scaleY(1)';
        }
    }, [open, width]);
    useEffect(() => {
        setOpen(false);
    }, [location]);

    //scroll disappear effect
    const [offset, setOffset] = useState(0);
    const [scrollDirection, setScrollDirection] = useState(0);
    const [mouseEntered, setMouseEntered] = useState(false);
    useEffect(() => {
        const onScroll = () => setOffset(offset => {
            const newOffset = window.scrollY;
            setScrollDirection(newOffset < offset ? -1 : 1);
            return newOffset;
        });
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);
    useEffect(() => {
        if(!navbarRef.current) return;
        if(scrollDirection > 0 && offset > navbarRef.current.offsetHeight && !open && (!mouseEntered || width < 900)) {
            navbarRef.current.style.top = `-${navbarRef.current.offsetHeight}px`;
        } else {
            navbarRef.current.style.top = '0';
            if(scrollDirection !== 0) {
                setScrollDirection(0);
            }
        }
    }, [offset, scrollDirection, open, mouseEntered, width]);

    //search
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchInFocus, setSearchInFocus] = useState(false);

    useEffect(() => { (async () => {
        if(searchValue.length <= 2) {
            setSearchResults([]);
            return;
        }
        const { results } = await algolia.multipleQueries([
            {
                indexName: 'index',
                query: searchValue,
                params: { filters: 'visibility:"public" AND recordType:"artist" OR recordType:"organizer" OR recordType:"recurring_event"', hitsPerPage: 2 },
            },
            {
                indexName: 'index',
                query: searchValue,
                params: { filters: `visibility:"public" AND recordType:"event" AND start._seconds >= ${parseInt(Date.now() / 1000)}`, hitsPerPage: 5 },
            },
        ]);
        const hits = results.map(result => result.hits).flat();
        const cityResults = cities?.filter(e => (e.id + e.data().name).toLowerCase().includes(searchValue.toLowerCase())).map(e => ({
            objectID: 'city-' + e.id,
            name: e.data().name,
            recordType: 'city',
        }))??[];
        setSearchResults([...cityResults, ...hits]);
    })() }, [searchValue, cities]);

    return <div ref={navbarRef} className="navbar" onMouseEnter={() => setMouseEntered(true)} onMouseLeave={() => setMouseEntered(false)}>
        <Link to="/">
            <img src={logo} alt="ELGIO Logo" className="logo" />
        </Link>
        <div className="nav-search">
            <div className="bg-neutral-800 rounded-full flex items-center h-full w-full px-3">
                {searchIcon}
                <input className="bg-transparent flex-1 focus:outline-none ml-3 mt-1" placeholder="Suche" value={searchValue} onChange={e => setSearchValue(e.target.value)} onFocus={() => setSearchInFocus(true)} onBlur={() => setTimeout(() => setSearchInFocus(false), 250)} />
            </div>
            {searchInFocus && searchResults.length > 0 && <div className="relative">
                <div className="nav-search-results">
                    {searchResults.map(result => <Link to={`/${result.recordType.replace('_', '-')}/${result.objectID.split('-')[1]}`} className="flex" onClick={_ => setSearchValue('')}>
                        {result.recordType === 'city' && <div className="h-16 w-16 md:w-24 shrink-0 bg-neutral-800 rounded flex items-center justify-center text-2xl">
                            {cityIcon}
                        </div>}
                        {result.recordType !== 'city' && <FirebaseStorageImage reference={result.recordType === 'organizer' && result.icon ? result.icon : result.images?.length > 0 ? result.images[0] : null} className={['artist', 'organizer'].includes(result.recordType) ? 'h-16 w-16 md:mx-4 shrink-0 object-cover rounded-full' : 'h-16 w-16 md:w-24 shrink-0 object-cover rounded'} />}
                        <div className="p-3 min-w-0 flex flex-col justify-center">
                            <div className="text-ellipsis whitespace-nowrap medium t-black overflow-hidden">{result.name}</div>
                            {result.recordType === 'event' && <div className="small">
                                <span className="t-blue">{toDateString(new Date((result.start?._seconds??result.start?.seconds??0) * 1000))}</span>
                                <span className="t-grey"><EventLocation event={result} /></span>
                            </div>}
                            {result.recordType === 'recurring_event' && <div className="small">
                                <span className="t-blue">{resolveRepeat(result.repeat)}</span>
                                <span className="t-grey"><EventLocation event={result} /></span>
                            </div>}
                            {result.recordType === 'artist' && <div className="small t-black">Artist</div>}
                            {result.recordType === 'city' && <div className="small t-grey whitespace-nowrap text-ellipsis overflow-hidden">Entdecke Events in {result.name}</div>}
                            {result.recordType === 'organizer' && result.type === 'venue' && <div className="small t-grey">Club</div>}
                            {result.recordType === 'organizer' && result.type !== 'venue' && <div className="small t-grey">Veranstalter</div>}
                        </div>
                    </Link>)}
                </div>
            </div>}
        </div>
        <div className="nav-button" onClick={() => setOpen(e => !e)}>
            <FontAwesomeIcon icon={showEasterEgg ? faHamburger : faBars} />
        </div>
        <div ref={contentRef} className="nav-content">
            <div className="nav-option">
                <Link to="/">Home</Link>
            </div>
            <div className="nav-option">
                <Link to="/discover">Events entdecken</Link>
            </div>
            <OptionWithSubMenu to="/for-organizers" label="Für Veranstalter">
                <div className="nav-suboption">
                    <Link to="/for-organizers/event-management" className="suboptionRow">
                        <div className="navIconBox">{eventsIcon}</div>
                        <div className="leading-none">Events</div>
                    </Link>
                </div>
                <div className="nav-suboption">
                    <Link to="/for-organizers/ticketing" className="suboptionRow">
                        <div className="navIconBox">{ticketingIcon}</div>
                        <div className="leading-none">Ticketing</div>
                    </Link>
                </div>
                <div className="nav-suboption">
                    <Link to="/for-organizers/marketing" className="suboptionRow">
                        <div className="navIconBox">{marketingIcon}</div>
                        <div className="leading-none">Marketing</div>
                    </Link>
                </div>
                <div className="nav-suboption">
                    <Link to="/for-organizers/website" className="suboptionRow">
                        <div className="navIconBox">{websiteIcon}</div>
                        <div className="leading-none">Website</div>
                    </Link>
                </div>
                <div className="nav-suboption">
                    <Link to="/for-organizers/statistics" className="suboptionRow">
                        <div className="navIconBox">{statisticsIcon}</div>
                        <div className="leading-none">Statistiken</div>
                    </Link>
                </div>
                <div className="nav-suboption">
                    <Link to="/for-organizers/pricing" id="preise">
                        <div>Preise</div>
                        <div className="small">{rightIcon}</div>
                    </Link>
                </div>
            </OptionWithSubMenu>
            <div className="nav-download">
                <Link to="/download">App holen</Link>
            </div>
        </div>
    </div>;
}

function OptionWithSubMenu({children, to, label}) {
    const subNavRef = useRef();
    const blockerRef = useRef();

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const onResize = () => setWidth(window.innerWidth);
        window.removeEventListener('resize', onResize);
        window.addEventListener('resize', onResize, { passive: true });
        return () => window.removeEventListener('resize', onResize);
    }, []);

    const [open, setOpen] = useState(false);
    const [timer, setTimer] = useState(null);
    useEffect(() => {
        if (!subNavRef.current || !blockerRef.current) return;
    
        if (!open) {
            subNavRef.current.style.transform = 'scaleY(0)';
            if (!timer) {
                const newTimer = setTimeout(() => {
                    if (subNavRef.current) {
                        subNavRef.current.style.visibility = 'hidden';
                    }
                    if (blockerRef.current) {
                        blockerRef.current.style.display = 'none';
                    }
                    setTimer(null);
                }, 400);
                setTimer(newTimer);
            }
        } else {
            if (subNavRef.current) {
                subNavRef.current.style.transform = 'scaleY(1)';
                subNavRef.current.style.visibility = 'visible';
            }
            if (width < 900 && blockerRef.current) {
                blockerRef.current.style.display = 'flex';
                if (timer) {
                    clearTimeout(timer);
                    setTimer(null);
                }
            }
        }
    }, [open, timer, width, subNavRef, blockerRef]);

    return <div className="nav-option" onMouseEnter={() => width >= 900 && setOpen(true)} onMouseLeave={() => width >= 900 && setOpen(false)}>
        <Link to={to}>{label}</Link>
        <div onClick={() => setOpen(e => !e)}>{downIcon}</div>
        <div ref={blockerRef} className="nav-submenu blocker">
            {children}
        </div>
        <div ref={subNavRef} className="nav-submenu">
            {children}
        </div>
    </div>;
}

function EventLocation({event}) {
    const { cities } = useContext(CityContext);
    const location = useMemo(() => {
        if(event.venue_data?.name) {
            return event.venue_data?.name;
        } else {
           return cities.find(e => e.id === event.city)?.data().name??event.city;
        }
    }, [event, cities]);
    return location ? ' · ' + location : null;
}